var $body = $("body");
var $favContainer = $("header").find("#favourites-container");
var $close = $favContainer.find(".close-slide");

var $cartToggle = $(".cart");
var $cartContainer = $("header").find("#cart-container");

var $addToCart = $(".add-to-cart");
var $addToCartQuantity = $(".add-to-cart-quantity");

$close.on("click", function () {
    "use strict";
    if ($favContainer.hasClass("show")) {
        $favContainer.removeClass("show");
    }
    if ($body.hasClass("overlay")) {
        $body.removeClass("overlay");
    }
});

var $closeCart = $cartContainer.find(".close-slide");
$closeCart.on("click", function () {
    "use strict";
    if ($cartContainer.hasClass("show")) {
        $cartContainer.removeClass("show");
    }
    if ($body.hasClass("overlay")) {
        $body.removeClass("overlay");
    }
});

var $accordionToggle = $("#accordionDelivery .card .card-header").find(".toggle");
var $radioButtonCheck = $accordionToggle.find(".radio-button > .radio-inner");
$accordionToggle.on("click", function () {
    "use strict";
    var $radioButton = $(this).find(".radio-inner");
    $radioButtonCheck.removeClass("checked");
    if ($radioButtonCheck.hasClass("checked")) {
        $radioButtonCheck.removeClass("checked");
    } else {
        $radioButton.addClass("checked");
    }
});

var $accordionPaymentToggle = $("#accordionPayment .card .card-header").find(".toggle");
var $radioButtonCheckPayment = $accordionPaymentToggle.find(".radio-button > .radio-inner");
$accordionPaymentToggle.on("click", function () {
    "use strict";
    var $radioButton = $(this).find(".radio-inner");
    $radioButtonCheckPayment.removeClass("checked");
    if ($radioButtonCheckPayment.hasClass("checked")) {
        $radioButtonCheckPayment.removeClass("checked");
    } else {
        $radioButton.addClass("checked");
    }
});

var $paymentToggle = $("#checkout").find(".payment-toggle");
var $paymentContainer = $("#checkout").find("#order-details");
$paymentToggle.on("click", function () {
    $(this).toggleClass("toggle");
    $paymentContainer.toggleClass("expanded");
    $body.toggleClass("overflow-hidden");
});

(function () {
    'use strict';
    window.addEventListener('load', function () {
        var forms = document.getElementsByClassName('needs-validation');
        var validation = Array.prototype.filter.call(forms, function (form) {
            form.addEventListener('submit', function (event) {
                if (form.checkValidity() === false) {
                    event.preventDefault();
                    event.stopPropagation();
                    $(form).addClass('has-errors');
                } else {
                    $(form).removeClass('has-errors');
                    $(form).addClass('submitting');
                }
                form.classList.add('was-validated');
            }, false);
        });
    }, false);
})();

var Cart = function (container, type) {
    this._body = $("body");
    this._addButton = $(".add-to-cart-button");
    this._cartContainer = container;
    this._type = type;
    this.initialize();
};
Cart.prototype = {
    _body: null,
    _addButton: null,
    _changeTimeout: null,
    _changeRequest: null,
    _cartContainer: null,
    _type: null,
    initialize: function () {

        var self = this;

        if (this._type == 'full') {
            $.get('/cart/cart?type=full', {}, function (html) {
                self.openWithHTML(html);
            });
        }
        if (this._type == 'side') {
            this._addButton.on('click', function () {
                var button = $(this);

                var qty = $(this).closest(".product-single").find(".add-to-cart-quantity");

                $.post('/cart/add', {
                    id: $(this).data('id'),
                    qty: qty.length == 0 ? 1 : (qty.val() > 0 ? qty.val() : 1),
                    options: [],
                    mobile: button.hasClass('add-to-cart-mobile')
                }, function (html) {
                    $('.alert.cart').addClass('show');
                    button.addClass('order-add');

                    var cartCount = $(html);
                    $(".header .account .order .count-order").html(cartCount.data("count")).show();

                    setTimeout(function () {
                        $(".alert.cart").removeClass('show');
                        button.removeClass('order-add');
                    }, 4000);
                    
                }).done(function(response) {
                    getStatus();
                });
                

                return false;
            });

            self._body.find('.side-cart-overlay').on('click', function () {
                self._body.removeClass('overlay');
                self._cartContainer.removeClass('show');
            });
        }
    },

    openWithHTML: function (html) {

        var self = this;

        if (this._type == 'side') {
            self._body.addClass('overlay');
        }
        self._cartContainer.html(html).addClass('show');

        self._cartContainer.find('.close-slide').on('click', function () {
            self._body.removeClass('overlay');
            self._cartContainer.removeClass('show');
        });

        self._cartContainer.find('.remove-button').on('click', function () {
            var product = $(this).parents('.product');

            var options = product.data('options') == '' ? [] : product.data('options').split(';');

            self.change('remove', {
                id: product.data('id'),
                options: options
            },
                $(this));

            return false;
        });

        self._cartContainer.find('.btn-plus').on('click', function () {
            var product = $(this).parents('.product');
            var qty = product.find('.qty');

            qty.val(parseInt(qty.val()) + 1).trigger('blur');

            return false;
        });

        self._cartContainer.find('.btn-minus').on('click', function () {
            var product = $(this).parents('.product');
            var qty = product.find('.qty');

            qty.val(parseInt(qty.val() - 1)).trigger('blur');

            return false;
        });

        self._cartContainer.find('.qty').on('blur', function () {
            var product = $(this).parents('.product');

            var options = product.data('options') == '' ? [] : product.data('options').split(';');

            self.change('qty', {
                id: product.data('id'),
                qty: product.find('.qty').val(),
                options: options
            }, $(this));

            return false;
        });

        self._cartContainer.find('.finish-order').on('click', function () {
            $("#order-details").append('<div class="list-loader text-loader"><div class="inner"></div><div class="text">Preusmerjamo na zaključek naročila</div></div>');
        });
    },
    change: function (action, data, element) {
        var self = this;

        if (self._changeRequest != null) {
            self._changeRequest.abort();
        }
        if (self._changeTimeout != null) {
            clearTimeout(self._changeTimeout);
        }

        self._changeTimeout = setTimeout(function () {

            element.parents('.product').find(".quantity.input-group").append('<div class="list-loader"><div class="inner"></div></div>');

            self._cartContainer.find('.cart-overlay').show();

            self._changeRequest = $.post('/cart/' + action + '?type=' + self._type, data, function (html) {
                self.openWithHTML(html);

                getStatus();
            });
        }, 600);
    }
};

var Inquiry = function (container, type) {
    this._body = $("body");
    this._addButton = $(".add-to-inquiry-button");
    this._cartContainer = container;
    this._type = type;
    this.initialize();
};
Inquiry.prototype = {
    _body: null,
    _addButton: null,
    _changeTimeout: null,
    _changeRequest: null,
    _cartContainer: null,
    _type: null,
    initialize: function () {

        var self = this;

        if (this._type == 'full') {
            $.get('/inquiry/inquiry?type=full', {}, function (html) {
                self.openWithHTML(html);
            });
        }
        if (this._type == 'side') {
            this._addButton.on('click', function () {
                var button = $(this);

                var qty = $(this).closest(".product-single").find(".add-to-cart-quantity");

                $.post('/inquiry/add', {
                    id: $(this).data('id'),
                    qty: qty.length == 0 ? 1 : (qty.val() > 0 ? qty.val() : 1),
                    options: [],
                    mobile: button.hasClass('add-to-inquiry-mobile')
                }, function (html) {
                    $('.alert.inquiry').addClass('show');
                    button.addClass('inquiry-add');

                    var inquiryCount = $(html);
                    $(".header .account .inquiry .count-inquiry").html(inquiryCount.data("count")).show();

                    setTimeout(function () {
                        $(".alert.inquiry").removeClass('show');
                        button.removeClass('inquiry-add');
                    }, 4000);
                }).done(function(response) {
                    getInquiryStatus();
                });

                return false;
            });

            self._body.find('.side-cart-overlay').on('click', function () {
                self._body.removeClass('overlay');
                self._cartContainer.removeClass('show');
            });
        }
    },

    openWithHTML: function (html) {

        var self = this;

        if (this._type == 'side') {
            self._body.addClass('overlay');
        }
        self._cartContainer.html(html).addClass('show');

        self._cartContainer.find('.close-slide').on('click', function () {
            self._body.removeClass('overlay');
            self._cartContainer.removeClass('show');
        });

        self._cartContainer.find('.remove-button').on('click', function () {
            var product = $(this).parents('.product');

            var options = product.data('options') == '' ? [] : product.data('options').split(';');

            self.change('remove', {
                id: product.data('id'),
                options: options
            },
                $(this));

            return false;
        });

        self._cartContainer.find('.btn-plus').on('click', function () {
            var product = $(this).parents('.product');
            var qty = product.find('.qty');

            qty.val(parseInt(qty.val()) + 1).trigger('blur');

            return false;
        });

        self._cartContainer.find('.btn-minus').on('click', function () {
            var product = $(this).parents('.product');
            var qty = product.find('.qty');

            qty.val(parseInt(qty.val() - 1)).trigger('blur');

            return false;
        });

        self._cartContainer.find('.qty').on('blur', function () {
            var product = $(this).parents('.product');

            var options = product.data('options') == '' ? [] : product.data('options').split(';');

            self.change('qty', {
                id: product.data('id'),
                qty: product.find('.qty').val(),
                options: options
            }, $(this));

            return false;
        });

        self._cartContainer.find('.finish-inquiry').on('click', function () {
            $("#order-details").append('<div class="list-loader text-loader"><div class="inner"></div><div class="text">Povpraševanje je v obdelavi</div></div>');
        });
    },
    change: function (action, data, element) {
        var self = this;

        if (self._changeRequest != null) {
            self._changeRequest.abort();
        }
        if (self._changeTimeout != null) {
            clearTimeout(self._changeTimeout);
        }

        self._changeTimeout = setTimeout(function () {

            element.parents('.product').find(".quantity.input-group").append('<div class="list-loader"><div class="inner"></div></div>');

            self._cartContainer.find('.cart-overlay').show();

            self._changeRequest = $.post('/inquiry/' + action + '?type=' + self._type, data, function (html) {
                self.openWithHTML(html);

                getInquiryStatus();
            });
        }, 600);
    }
};

var fullCartPlaceholder = $(".full-cart-placeholder");
var sideCartPlaceholder = $(".side-cart-placeholder");
if (fullCartPlaceholder.length > 0) {
    fullCartPlaceholder.prepend('<div class="list-loader"><div class="inner"></div></div>');
    new Cart(fullCartPlaceholder, 'full');
} else {
    new Cart(sideCartPlaceholder, 'side');
}

var fullInquiryPlaceholder = $(".full-inquiry-placeholder");
var sideInquiryPlaceholder = $(".side-inquiry-placeholder");
if (fullInquiryPlaceholder.length > 0) {
    fullInquiryPlaceholder.prepend('<div class="list-loader"><div class="inner"></div></div>');
    new Inquiry(fullInquiryPlaceholder, 'full');
} else {
    new Inquiry(sideInquiryPlaceholder, 'side');
}

getStatus();

function getStatus() {
    if (!checkPublicClass($("body"))) {
        $.get('/cart/status?onlyCount', function (response) {
            var data = JSON.parse(response);

            if (data.cart > 0) {
                $(".count-order").html(data.cart_items).show();
            } else {
                $(".count-order").hide();
            }
        });
    }
}

getInquiryStatus();

function getInquiryStatus() {
    if (!checkPublicClass($("body"))) {
        $.get('/inquiry/status?onlyCount', function (response) {
            var data = JSON.parse(response);
            if (data.inquiry_items > 0) {
                $(".count-inquiry").html(data.inquiry_items).show();
            } else {
                $(".count-inquiry").hide();
            }
        });
    }
}

var Checkout = function (container, type) {
    this.initialize();
};
Checkout.prototype = {

    initialize: function () {
        var self = this;
        if ($("#account-checkbox").is(':checked')) {
            $(".form-password").show().find('input').prop('required', true);
        }

        $("#account-checkbox").on('click', function () {
            if ($(this).is(':checked')) {
                $(".form-password").show().find('input').prop('required', true);
            } else {
                $(".form-password").hide().find('input').prop('required', false);
            }
        });

        $(".delivery-address").find('input').prop('required', false);
        $("#delivery-checkbox").on('click', function () {
            if ($(this).is(':checked')) {
                $(".delivery-address").show().find('input').prop('required', true);
            } else {
                $(".delivery-address").hide().find('input').prop('required', false);
            }
        });

        $(".address-card").on('click', function () {
            $(".address-card.active.selected").removeClass(['active', 'selected']);

            $(this).addClass(['active', 'selected']);

            var address = $(this).data('address');

            $(".delivery-address input[name=delivery_address_id]").val(address.id);

            Object.keys(address).forEach(function (key) {
                $(".delivery-address input[name=delivery_" + key + "]").val(address[key]);
            });
        });

        $("#delivery input, #payment input").on('change', function () {
            self.saveDeliveryChoice(
                $("input[name=payment_type_id]:checked").val(),
                $("input[name=delivery_type_id]:checked").val()
            );
        });

        $.get("/cart/checkout-summary", function (html) {
            self.applySummary(html);
        });
    },

    saveDeliveryChoice: function (payment, delivery) {
        var self = this;
        $.post('/cart/checkout-delivery', {
            payment_type_id: payment != null ? payment : null,
            delivery_type_id: delivery != null ? delivery : null
        }, function (html) {
            self.applySummary(html);
        });
    },
    applySummary: function (html) {
        var self = this;
        $(".checkout-summary-placeholder").html(html);
    }
};

if ($("#checkout").length > 0) {
    new Checkout();
}

var companyCheckbox = $(".personal").find("#company-checkbox");
var companyCont = $(".personal").find("#company");
companyCheckbox.on("click", function () {
    $(companyCont).toggleClass("show");
    var companyAddFields = $(companyCont).find("input[type=text]");
    $(companyAddFields).each(function (key, field) {
        $(field).attr("required", true);
    })
});

setTimeout(function () {
    if (companyCheckbox.is(":checked")) {
        $(companyCont).addClass("show");
    }
}, 500);